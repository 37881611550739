'use client'

import PropTypes from 'prop-types'
import * as Sentry from '@sentry/nextjs'
import NextError from 'next/error'
import React, { useEffect } from 'react'

export default function GlobalError ({ error }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html>
      <body>
        <NextError />
      </body>
    </html>
  )
}

GlobalError.propTypes = {
  error: PropTypes.any
}
